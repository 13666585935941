<template>
  <img alt="Zephyr logo" class="resized-image" src="./assets/Zephyr_logo.png">
  <!-- <LoginScreen msg="Welcome to Your Vue.js App"/> -->
  <RouterView/>

</template>

<script>
//import LoginScreen from './components/LoginScreen.vue'
import {RouterView} from 'vue-router';

export default {
  name: 'App',
  components: {
    //LoginScreen,
    RouterView,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.resized-image {
  /* Set maximum width and height while maintaining aspect ratio */
  max-width: 300px;
  max-height: 200px;
  width: auto;
  height: auto;
}
</style>
