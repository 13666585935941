<!-- SideMenu.vue -->
<template>
  <div class="side-menu">
    <ul>
      <li v-for="link in links" :key="link.id">
        <a :href="`#${link.id}`">{{ link.title }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      links: [
        { id: 'home', title: 'Home' },
        { id: 'feature-description', title: 'Feature Description' },
        { id: 'user-feature', title: 'User Feature' },
        { id: 'user-stories', title: 'User Stories' },
        { id: 'uat-scripts', title: 'UAT Scripts' },
      ]
    };
  }
};
</script>

<style scoped>
.side-menu {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 200px; /* Adjust the width as needed */
  background-color: #f8f9fa; /* Light gray background */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  margin-left: 25px;
}

.side-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.side-menu li {
  margin-bottom: 0;
}

.side-menu a {
  display: block;
  padding: 15px 20px;
  color: #333;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.side-menu a:hover {
  background-color: #e9ecef; /* Lighter background on hover */
}

/* Optional: Add styles for active link */
.side-menu a.active {
  background-color: #007bff; /* Blue background for active link */
  color: #fff; /* White text color */
}
</style>
