//import Vue from 'vue';
import { createRouter, createWebHistory} from 'vue-router';
//import Vue from 'vue';
//import Router from 'vue-router';
import LoginScreen from '@/views/LoginScreen.vue';
import MainComponent from '@/views/MainComponent.vue';

//Vue.use(Router);

/*const routes = [
  { path: '/', component: LoginScreen },
  { path: '/maincomponent', component: MainComponent}
];*/

/*const router = new VueRouter({
  routes
});*/

const router = createRouter({
  history: createWebHistory(),
  routes : [
    { path: '/', name: 'LoginScreen', component: LoginScreen },
    { path: '/maincomponent', name: 'MainComponent', component: MainComponent}
  
  ]
});

export default router;


/*new Vue({
  router
}).$mount('#app');*/
//createApp(App).mount('#app')
/*const app = Vue.createApp({
  routes,
});
app.mount('#app')*/

//createApp(VueRouter).mount('#app')
