//import Vue from 'vue';
import { createApp } from 'vue'
import App from './App.vue'
import './assets/bootstrap.css'; // Import the CSS file
import router from './router/loginRouter'; // Import the router


/*new Vue({
    router,
    render: h => h(App)
  }).$mount('#app');*/

// Use createApp to initialize your Vue application
const app = createApp(App);

if (!app) {
  console.error('Failed to create Vue app instance.');
} else {
  // Verify that the router object is valid before using it
  if (router) {
    app.use(router); // Use the router plugin
  } else {
    console.error('Router is not available or invalid.');
  }

  // Mount the app to the DOM element with id 'app'
  app.mount('#app');
}

//createApp(App).use(router).mount('#app')
//createApp(router).mount('#router')