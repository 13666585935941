<template>
  <div>
    <side-menu></side-menu>
  </div>

  <div>
    <section id="home">
    <h1>Welcome to Zephyr GenAI</h1>
    </section>

    <div id="app">
      <section id="feature-description">
        <div>
          <h4>Feature Description</h4>
        </div>

        <button @click="copyText" type="button" class="btn btn-outline-light">Copy</button>

        <div>
          <div>
            <textarea
              ref="textarea"
              type="text"
              id="featureDescription"
              v-model="featureDescription"
              class="custom-input-textarea"
            ></textarea>

            </div>
          <div class="progress progress-container">
            <div
              :class="loadingBarFeatureClass"
              role="progressbar"
              :style="{ width: `${progress}%` }"
              aria-valuenow="50"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <div v-if="progress === 100">
            <p class ="custom-span">
              <span>{{
                apiResponseFeatureDescription.featureDescription
              }}</span>
            </p>
            <p>Quality Score: {{ apiResponseFeatureDescription.qualityScore }}</p>
          </div>
        </div>

        <div>
          <button @click="submitData" type="button" class="btn btn-outline-light">
            Submit
          </button>
        </div>

      </section>

      <section id="user-feature">
        <div>
          <h4 class="custom-header">User Feature</h4>
        </div>

        <div>
          <textarea
            ref="textarea"
            type="text"
            id="userFeature"
            v-model="apiResponseUserFeature.userFeature"
            class="custom-input-textarea"
          ></textarea>
          <div class="progress progress-container">
            <div
              class="progress-bar progress-bar-striped bg-success"
              role="progressbar"
              :style="{ width: `${userFeatureProgress}%` }"
              aria-valuenow="50"
              aria-valuemin="0"
              aria-valuemax="100"
            >
            </div>
          </div>
          <button
            @click="submitDataUserFeature"
            type="button"
            class="btn btn-outline-light">
            Generate
          </button>
        </div>
      </section>

      <section id="user-stories">
        <div>
          <h4 class="custom-header">User Stories</h4>
        </div>

        <div>
          <textarea
            ref="textarea"
            type="text"
            id="userStories"
            v-model="apiResponseUserStories.userStories"
            class="custom-input-textarea"
          ></textarea>
          <div class="progress progress-container">
            <div
              class="progress-bar progress-bar-striped bg-success"
              role="progressbar"
              :style="{ width: `${userStoryProgress}%` }"
              aria-valuenow="50"
              aria-valuemin="0"
              aria-valuemax="100"
            >
            </div>
          </div>

          <button
            @click="submitDataUserStories"
            type="button"
            class="btn btn-outline-light">
            Generate
          </button>

        </div>
      </section>

      <section id="uat-scripts">
        <div>
          <h4 class="custom-header">UAT Scripts</h4>
        </div>

        <div>
          <textarea
            ref="textarea"
            type="text"
            id="uatScript"
            v-model="apiResponseUATScript.uatScript"
            class="custom-input-textarea"
          ></textarea>
          <div class="progress progress-container">
            <div
              class="progress-bar progress-bar-striped bg-success"
              role="progressbar"
              :style="{ width: `${UATProgress}%` }"
              aria-valuenow="50"
              aria-valuemin="0"
              aria-valuemax="100"
            >
            </div>
          </div>

          <button
            @click="submitDataUATScript"
            type="button"
            class="btn btn-outline-light">
            Generate
          </button>

        </div>
      </section>


    </div>
  </div>
</template>

<script>
import axios from "axios";
import copy from 'clipboard-copy';
import SideMenu from '../components/SideMenu.vue';

export default {
  components: {
    SideMenu
  },
  data() {
    return {
      featureDescription: "",
      apiResponseFeatureDescription: "",
      userFeature: "",
      apiResponseUserFeature: { userFeature: "" },
      userStories: "",
      apiResponseUserStories: { userStories: "" },
      uatScript: "",
      apiResponseUATScript: { uatScript: "" },

      progress: 0,
      userFeatureProgress: 0,
      userStoryProgress: 0,
      UATProgress: 0,
      loadingInterval: null,
      loadingIntervalUserFeature: null,
      loadingIntervalUserStory: null,
      loadingIntervalUAT: null,
      //classFeatureDescription: "progress-bar progress-bar-striped bg-info",
    };
  },

  computed: {
    /*dynamicClass() {
      // Return the class name based on the condition
      if (this.apiResponseFeatureDescription.qualityScore < 0.5) {
        this.classFeatureDescription = "progress-bar bg-danger";
      } else if (this.apiResponseFeatureDescription.qualityScore < 0.75) {
        this.classFeatureDescription = "progress-bar bg-warning";
      } else {
        this.classFeatureDescription = "progress-bar bg-success";
      }
      return this.apiResponseFeatureDescription.qualityScore === 0.5 ? 'progress-bar bg-danger' : 'progress-bar bg-success';
    },
  },*/
    loadingBarFeatureClass() {
      // Define your class logic based on the loadingProgress value
      return {
        "progress-bar progress-bar-striped bg-info": this.progress < 100,
        "progress-bar bg-success":
          this.apiResponseFeatureDescription.qualityScore >= 0.75 &&
          this.progress === 100,
        //'progress-bar bg-warning': this.apiResponseFeatureDescription.qualityScore > 0.75 && this.apiResponseFeatureDescription.qualityScore <= 0.5,
        "progress-bar bg-danger":
          this.apiResponseFeatureDescription.qualityScore < 0.75 &&
          this.progress === 100,
      };
    },
  },

  methods: {
    copyText() {
      if (this.featureDescription) {
        copy(this.featureDescription)
          .then(() => {
            alert('Text copied!');
          })
          .catch(() => {
            alert('Failed to copy text. Please try again.');
          });
      }
    },

    submitData() {
      // Reset progress
      this.progress = 0;

      // Simulate loading with a setInterval
      //this.loadingInterval = setInterval(() => {
        console.log(this.progress);
        // Make an API call using axios (replace the URL with your API endpoint)
        //if (this.progress == 90) {
          axios
            .post(process.env + "feature-description", {
              data: this.featureDescription,
            })
            .then((response) => {
              console.log("API Response:", response.data);
              this.apiResponseFeatureDescription = response.data;
              this.progress = 100;
              clearInterval(this.loadingInterval);
              // Handle the API response as needed
            })
            .catch((error) => {
              console.error("API Error:", error);
            });
        //}

        if (this.progress < 100) {
          this.progress += 5; // Increment the progress
        } else {
          // Loading completed, clear the interval
          clearInterval(this.loadingInterval);
        }
      //}, 500);
    },

    submitDataUserFeature() {
      this.userFeatureProgress = 0;
      //this.loadingInterval = setInterval(() => {
        //if (this.progress == 90) {
        // Make an API call using axios (replace the URL with your API endpoint)
        axios
          .post(process.env + "user-feature", {
            data: this.featureDescription,
          })
          .then((response) => {
            console.log("API Response:", response.data);
            this.apiResponseUserFeature = response.data;
            // Handle the API response as needed
          })
          .catch((error) => {
            console.error("API Error:", error);
          });
        //}
          console.log(this.userFeatureProgress);
          if (this.userFeatureProgress < 100) {
            this.userFeatureProgress += 5; // Increment the progress
          } else {
            // Loading completed, clear the interval
            clearInterval(this.loadingIntervalUserFeature);
          }
      //}, 500);
    },

    submitDataUserStories() {
      // Make an API call using axios (replace the URL with your API endpoint)
      axios
        .post(process.env + "user-stories", {
          data: this.userFeature,
        })
        .then((response) => {
          console.log("API Response:", response.data);
          this.apiResponseUserStories = response.data;
          // Handle the API response as needed
        })
        .catch((error) => {
          console.error("API Error:", error);
        });
        if (this.userStoryProgress < 100) {
          this.userStoryProgress += 5; // Increment the progress
        } else {
          // Loading completed, clear the interval
          clearInterval(this.loadingIntervalUserStory);
        }
    },

    submitDataUATScript() {
      // Make an API call using axios (replace the URL with your API endpoint)
      axios
        .post(process.env + "uat-script", {
          data: this.userStories,
        })
        .then((response) => {
          console.log("API Response:", response.data);
          this.apiResponseUATScript = response.data;
          // Handle the API response as needed
        })
        .catch((error) => {
          console.error("API Error:", error);
        });
        if (this.UATProgress< 100) {
          this.UATProgress += 5; // Increment the progress
        } else {
          // Loading completed, clear the interval
          clearInterval(this.loadingIntervalUAT);
        }
    },

    // resizeTextarea() {
    // Resize the textarea based on its content
    //this.$refs.textarea.style.height = 'auto';
    //this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + 'px';
    //},

    //handleWindowResize() {
    //  this.resizeTextarea();
    //},
  },

  mounted() {
    // Resize textarea when the component is mounted
    //this.resizeTextarea();

    // Add event listener for window resize
    window.addEventListener("resize", this.handleWindowResize);
  },
  beforeUnmount() {
    // Remove event listener to prevent memory leaks
    window.removeEventListener("resize", this.handleWindowResize);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

input {
  resize: none; /* Disable the textarea's native resizing */
  overflow-y: auto; /* Add a vertical scrollbar when needed */
  width: 100%; /* Set the width to 100% */
  height: 300px; /* Set the initial height */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.content {
  margin-left: 200px; /* Adjust based on the width of the side menu */
}

.progress-container {
  width: 800px; /* Set the desired width of the progress bar container */
  margin: 0 auto; /* Center the container horizontally */
}

.custom-span {
  margin-top: 20px; /* Set margin for the top */
  margin-right: 200px; /* Set margin for the right */
  margin-bottom: 20px; /* Set margin for the bottom */
  margin-left: 200px; /* Set margin for the left */
}

.custom-header {
  margin-top: 40px; /* Set margin for the top */
  margin-bottom: 10px; /* Set margin for the bottom */
}

</style>
